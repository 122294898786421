<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" />

    <TableHeader :selected-length="selectedDatas.length" :per-page="perPage" :disable-filter="true"
      :has-create-button="false" @per-page-change="perPage = $event">
      <template #button-export-items>
        <b-button variant="outline-primary" size="sm" @click="openModalExportMaster()">
          <Feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-25">Export</span>
        </b-button>
      </template>
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="CopyIcon" />
          Salin ke Produk Utama ({{ selectedDatas.length }})
        </b-dropdown-item>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item>
      </template>

      <template #buttons-group>
        <!-- sinkron button -->
        <b-button variant="success" size="sm" @click="syncProduct">
          <Feather-icon icon="RefreshCwIcon" />
          Sinkron Produk
        </b-button>
      </template>
    </TableHeader>

    <b-table-simple striped responsive style="zoom: 80%" class="position-relative">
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 5%">
            <b-form-checkbox v-model="selectAll" />
          </b-th>
          <b-th class="bg-transparent" style="width: 40%">Produk</b-th>
          <b-th class="bg-transparent">Harga</b-th>
          <b-th class="bg-transparent">Stok</b-th>
          <b-th class="bg-transparent">Status</b-th>
          <b-th class="bg-transparent">Terakhir diubah</b-th>
          <b-th class="bg-transparent"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr v-for="(data, index) in tableData" :key="index">
          <b-td>x</b-td>
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="7" />
    </b-table-simple>

    <component :is="modalExportProductComponent" v-bind="{ productData }" />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import SidebarSearch from '@/components/SidebarSearch.vue';
import TableHeader from '@/components/TableHeader.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';

// import mixins
import marketplaceMixins from './mixins/marketplaceMixins';
import axios from '@axios';

export default defineComponent({
  name: 'MarketplaceLazadaList',
  mixins: [marketplaceMixins],
  components: {
    BRow,
    BCol,
    BCard,
    SidebarSearch,
    TableHeader,
    EmptyTableRow,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    // await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      tableData: [
        // {
        //   id: 1,
        //   name: 'Product 1',
        //   price: 1000,
        //   stock: 10,
        //   status: 'Active',
        //   lastUpdated: '2021-01-01',
        // },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      productData: {},
      modalExportProductComponent: () =>
        import('./ModalExportProduct.vue'),
      searchComponent: [
        {
          name: 'Pencarian Produk',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: '',
        },
        {
          label: 'Urutkan berdasarkan',
          name: 'sortBy',
          type: 'select',
          value: null,
          options: [
            { value: '1', text: 'Tanggal (Terbaru)' },
            { value: '2', text: 'Tanggal (Terlama)' },
            { value: '3', text: 'Harga (Tertinggi)' },
            { value: '4', text: 'Harga (Terendah)' },
            { value: '5', text: 'Stok (Tertinggi)' },
            { value: '6', text: 'Stok (Terendah)' },
          ],
        },
        {
          label: 'Kondisi Produk',
          name: 'condition',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Baru' },
            { value: '2', title: 'Bekas' },
          ],
        },
        {
          label: 'Status Produk',
          name: 'status',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Aktif' },
            { value: '2', title: 'Best (Feature Product)' },
            { value: '3', title: 'Tidak Aktif (Gudang)' },
            { value: '-1', title: 'Pending' },
            { value: '-2', title: 'Banned' },
            { value: '0', title: 'Terhapus' },
          ],
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ns: false,
      };

      if (this.search) {
        // loop search to get key and value
        Object.keys(this.search).forEach(key => {
          if (this.search[key]) {
            // if contain object of array title and value get the value
            if (Array.isArray(this.search[key])) {
              params[key] = this.search[key].map(item => item.value);
            } else {
              params[key] = this.search[key];
            }
          }
        });
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`shopee/product/sync?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.data &&
        response.data.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data.data];

        const rupiah = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        });

        this.tableData.forEach(item => {
          item.product.price.rupiah = rupiah.format(item.product.price.value);
          item.stats = {
            transaction:
              item.product.GMStats && item.product.GMStats.transactionSuccess
                ? item.product.GMStats.transactionSuccess
                : 0,
            sold:
              item.product.GMStats && item.product.GMStats.countSold
                ? item.product.GMStats.countSold
                : 0,
          };
        });
      }

      this.totalRows = response.data.data.total;
      this.currentPage += 1;
    },

    async searchData(search = null) {
      this.search = search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;

      await this.fetchData();
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },

    async openModalExportMaster() {
      this.productData = this.tableData;
      this.$bvModal.show('modal-export-product');
    },
  },
});
</script>
